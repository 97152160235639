import React from "react";

import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

import { SkeletonComponent } from "@/lib/components";
import MDBox from "@/lib/components/md-box";
import MDTypography from "@/lib/components/md-typography";

interface CardLineChartPlaceholderComponentProps {
  title?: string;
  icon?: React.ReactNode;
  height?: string | number;
  style: React.CSSProperties;
}

export const CardLineChartPlaceholderComponent = ({
  title,
  height,
  style,
  icon,
}: CardLineChartPlaceholderComponentProps) => {
  return (
    <Card sx={{ marginY: 2, width: "100%" }}>
      <MDBox
        display="flex"
        flexDirection="column"
        width="100%"
        overflow="hidden"
        lineHeight={1}
        pl={2}
        pb={2}
      >
        <MDBox display="flex" alignItems="center" minHeight="1.05em" justifyContent="space-between">
          {title && (
            <MDTypography
              variant="button"
              textTransform="capitalize"
              fontWeight="medium"
              color="text"
              noWrap
            >
              {title}
            </MDTypography>
          )}
          {icon && (
            <Icon fontSize="medium" color="disabled" sx={{ marginRight: 1 }}>
              {icon}
            </Icon>
          )}
        </MDBox>
        <MDBox display="flex" minHeight={"1.375em"}>
          <MDTypography variant="h5" fontWeight="bold" color="dark" marginRight={1}>
            <SkeletonComponent width={120} />
          </MDTypography>
        </MDBox>
      </MDBox>
      <MDBox sx={{ width: "100%", height }}>
        <SkeletonComponent width={"100%"} height={"100%"} variant="rounded" style={style} />
      </MDBox>
    </Card>
  );
};
