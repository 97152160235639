import React from "react";

import { SelectChangeEvent } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import InputAdornment from "@mui/material/InputAdornment";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

import MDTypography from "../md-typography";
import { KeyValue } from "@/models";

export type SelectComponentProps = {
  value: string;
  menuItems: KeyValue[];
  onChange: (value: string) => void;
  label?: string;
  name?: string;
  disabled?: boolean;
  readOnly?: boolean;
  fullWidth?: boolean;
  isError?: boolean;
  isLoading?: boolean;
};

export const SelectComponent = ({
  value,
  menuItems,
  onChange,
  disabled,
  readOnly,
  fullWidth,
  isError,
  label,
  name,
  isLoading,
}: SelectComponentProps) => {
  const handleOnChange = (event: SelectChangeEvent) => {
    onChange(event.target.value);
  };
  return (
    <>
      {!!label && (
        <MDTypography variant="body2" fontWeight="bold" marginBottom="1.5ch">
          {label}
        </MDTypography>
      )}
      <Select
        id={"select-" + name}
        value={value}
        disabled={disabled}
        readOnly={readOnly}
        fullWidth={fullWidth}
        error={isError}
        onChange={handleOnChange}
        style={{ width: "100%", padding: ".5rem" }}
        endAdornment={
          isLoading ? (
            <InputAdornment position="end" style={{ marginRight: "1.5em" }}>
              <CircularProgress size={20} color="info" />
            </InputAdornment>
          ) : null
        }
      >
        {menuItems.map((item, index) => (
          <MenuItem key={index} value={item.key}>
            <MDTypography variant="body2">{item.value}</MDTypography>
          </MenuItem>
        ))}
      </Select>
    </>
  );
};
