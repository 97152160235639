import * as React from "react";

import CloseIcon from "@mui/icons-material/Close";
import { useTheme } from "@mui/material";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Modal from "@mui/material/Modal";

interface ModalComponentProps {
  open: boolean;
  handleClose: () => void;
  header?: React.ReactNode;
  content?: React.ReactNode;
  footer?: React.ReactNode;
}

export const ModalComponent: React.FC<ModalComponentProps> = ({
  open,
  header,
  content,
  footer,
  handleClose,
}) => {
  const theme = useTheme();

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-modal="true"
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Grid
        container
        sx={{
          position: "relative",
          backgroundColor: theme.palette.background.default,
          maxHeight: "90%",
          maxWidth: { xs: "90%", lg: 800 },
          borderRadius: 1,
        }}
      >
        <Grid item xs={12}>
          <IconButton
            onClick={handleClose}
            sx={{
              position: "absolute",
              top: 0,
              right: 0,
            }}
          >
            <CloseIcon />
          </IconButton>

          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              flexDirection: "column",
              padding: 5,
              gap: 1,
              overflow: "auto",
              maxHeight: "80vh",
              scrollbarWidth: "none",
              "&::-webkit-scrollbar": {
                display: "none",
              },
            }}
          >
            {header && (
              <Grid item xs={12}>
                {header}
              </Grid>
            )}
            {content && (
              <Grid item xs={12}>
                {content}
              </Grid>
            )}
            {footer && (
              <Grid item xs={12}>
                {footer}
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Modal>
  );
};
