import React from "react";

import Card from "@mui/material/Card";
import { Line } from "react-chartjs-2";

import { useCardLineChartComponent } from "./card-line-chart.hook";
import { CardLineChartComponentProps } from "./card-line-chart.models";
import {
  CardLineChartCountAndPercentageComponent,
  CardLineChartPlaceholderComponent,
  CardLineChartTitleComponent,
} from "./components";
import MDBox from "@/lib/components/md-box";

export const CardLineChartComponent = ({
  header: { title, icon },
  body: { count, percentage, tooltip },
  emptyDataComponent,
  chart: { labelName, data, isLoading, options = {}, style = {} },
}: CardLineChartComponentProps) => {
  const {
    color = "info",
    width = 385,
    height = 90,
    borderBottomLeftRadius = "0.75rem",
    borderBottomRightRadius = "0.75rem",
    ...otherStyles
  } = style;

  const { chartRef, labels, chartDatasets, chartOptions, onShowTooltip, onHideTooltip } =
    useCardLineChartComponent({
      labelName,
      data,
      color,
      options,
    });

  // Show placeholder component if loading
  if (isLoading) {
    return (
      <CardLineChartPlaceholderComponent
        title={title}
        icon={icon}
        height={height}
        style={otherStyles}
      />
    );
  }

  // Show empty data component if no data is available and emptyDataComponent is provided
  if (!data.length && emptyDataComponent) {
    return emptyDataComponent;
  }

  return (
    <Card sx={{ marginY: 2, width: "100%" }}>
      <MDBox
        display="flex"
        flexDirection="column"
        width="100%"
        overflow="hidden"
        lineHeight={1}
        pl={2}
        pb={2}
      >
        {/* Render title and icon from header */}
        <CardLineChartTitleComponent title={title} icon={icon} />

        {/* Render count and percentage from body */}
        <CardLineChartCountAndPercentageComponent
          count={count}
          percentage={percentage}
          tooltip={tooltip}
        />
      </MDBox>

      {/* Chart */}
      <MDBox ref={chartRef}>
        <Line
          data={{
            labels,
            datasets: chartDatasets,
          }}
          options={chartOptions}
          onTouchStart={onShowTooltip}
          onTouchEnd={onHideTooltip}
          width={width}
          height={height}
          style={{
            borderBottomLeftRadius,
            borderBottomRightRadius,
            ...otherStyles,
          }}
        />
      </MDBox>
    </Card>
  );
};
